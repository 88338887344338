import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Grid,
  InputAdornment,
  Divider,
  Radio,
} from '@mui/material';
import {
  MoreVertical,
  Edit,
  Trash,
  Plus,
  Package,
  Grid as GridIcon,
  Tag,
  Search,
  Cloud,
  Link,
} from 'react-feather';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';
import Toggle from './Toggle';
import { MercadoLivreCheckout } from './checkoutTemplates';
import { Link as RouterLink } from 'react-router-dom';

// Updated font styles
const interFont = {
  fontFamily: '"Inter", sans-serif',
};

// Updated color palette based on theme
const getThemeColors = (theme) => ({
  primary: '#0067e8',
  primaryHover: '#0052ba',
  background: theme.palette.background.default,
  border: theme.palette.divider,
  text: {
    primary: theme.palette.text.primary,
    secondary: theme.palette.text.secondary,
    white: theme.palette.background.paper
  }
});

const ProductForm = ({ open, handleClose, handleSubmit, initialData = null }) => {
  const theme = useTheme();
  const colors = getThemeColors(theme);
  const defaultFormData = {
    title: '',
    description: '',
    price: '',
    type: 'Digital',
    picture_url: '',
    template: 'hotmart',
    gateway: '',
  };

  const [formData, setFormData] = useState(initialData || defaultFormData);
  const [showCheckoutPreview, setShowCheckoutPreview] = useState(false);
  const [gateways, setGateways] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData(initialData || defaultFormData);
    setShowCheckoutPreview(false);
  }, [initialData, open]);

  // Fetch available gateways when form opens
  useEffect(() => {
    const fetchGateways = async () => {
      try {
        setLoading(true);
        const response = await api.get('/gateway/statuses/');
        setGateways(response.data.gateways);
      } catch (err) {
        console.error('Error fetching gateways:', err);
        setError('Failed to load payment gateways');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchGateways();
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'price') {
      // Remove non-numeric characters and convert to number
      const numericValue = value.replace(/[^\d]/g, '');
      const floatValue = (parseFloat(numericValue) / 100).toFixed(2);
      setFormData((prev) => ({
        ...prev,
        [name]: floatValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      
      // Show checkout preview when Mercado Livre is selected
      if (name === 'template') {
        setShowCheckoutPreview(value === 'mercadolivre');
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  // Updated templates with availability status
  const templates = [
    { value: 'hotmart', label: 'Hotmart' },
    { value: 'mercadolivre', label: 'Mercado Pago' },
    { value: 'vega', label: 'Vega Checkout', disabled: true },
    { value: 'kirvano', label: 'Kirvano', disabled: true },
  ];

  const formatPrice = (value) => {
    if (!value) return '';
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: '10px',
          bgcolor: colors.text.white,
          border: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
          maxWidth: '900px',
          margin: '20px'
        }
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle sx={{ 
          p: 2.5,
          borderBottom: `1px solid ${colors.border}`,
          '& .MuiTypography-root': { 
            ...interFont,
            fontSize: '18px',
            fontWeight: 600,
            color: colors.text.primary,
          }
        }}>
          {initialData ? 'Editar Produto' : 'Adicionar Novo Produto'}
        </DialogTitle>
        <DialogContent sx={{ p: 2.5 }}>
          <Grid container spacing={2.5}>
            {showCheckoutPreview ? (
              <>
                <Grid item xs={12} md={6}>
                  {/* Product Form Fields */}
                  <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ...interFont, 
                          color: colors.text.secondary,
                          mb: 1,
                          fontWeight: 500 
                        }}
                      >
                        Título
                      </Typography>
                      <TextField
                        fullWidth
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                        placeholder="Insira o título do produto"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '& fieldset': {
                              borderColor: colors.border,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          },
                          '& input': {
                            ...interFont,
                            padding: '12px 14px',
                            color: colors.text.primary,
                            '&::placeholder': {
                              color: colors.text.secondary,
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ...interFont, 
                          color: colors.text.secondary,
                          mb: 1,
                          fontWeight: 500 
                        }}
                      >
                        Descrição
                      </Typography>
                      <TextField
                        fullWidth
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        multiline
                        rows={4}
                        placeholder="Insira a descrição do produto"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            padding: '0px',
                            '& fieldset': {
                              borderColor: colors.border,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          },
                          '& textarea': {
                            ...interFont,
                            padding: '12px 14px',
                            color: colors.text.primary,
                            '&::placeholder': {
                              color: colors.text.secondary,
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ...interFont, 
                          color: colors.text.secondary,
                          mb: 1,
                          fontWeight: 500 
                        }}
                      >
                        Preço
                      </Typography>
                      <TextField
                        fullWidth
                        name="price"
                        type="text"
                        value={formatPrice(formData.price)}
                        onChange={handleInputChange}
                        required
                        placeholder="R$ 0,00"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '& fieldset': {
                              borderColor: colors.border,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          },
                          '& input': {
                            ...interFont,
                            padding: '12px 14px',
                            color: colors.text.primary,
                            '&::placeholder': {
                              color: colors.text.secondary,
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ...interFont, 
                          color: colors.text.secondary,
                          mb: 1,
                          fontWeight: 500 
                        }}
                      >
                        Tipo
                      </Typography>
                      <TextField
                        fullWidth
                        select
                        name="type"
                        value={formData.type}
                        onChange={handleInputChange}
                        required
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '& fieldset': {
                              borderColor: colors.border,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          },
                          '& .MuiSelect-select': {
                            ...interFont,
                            padding: '12px 14px',
                            color: colors.text.primary,
                          },
                        }}
                      >
                        <MenuItem value="Digital" sx={{ ...interFont }}>Digital</MenuItem>
                        <MenuItem value="Physical" sx={{ ...interFont }}>Físico</MenuItem>
                        <MenuItem value="Service" sx={{ ...interFont }}>Serviço</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ...interFont, 
                          color: colors.text.secondary,
                          mb: 1,
                          fontWeight: 500 
                        }}
                      >
                        URL da Imagem
                      </Typography>
                      <TextField
                        fullWidth
                        name="picture_url"
                        value={formData.picture_url}
                        onChange={handleInputChange}
                        placeholder="https://"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '& fieldset': {
                              borderColor: colors.border,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          },
                          '& input': {
                            ...interFont,
                            padding: '12px 14px',
                            color: colors.text.primary,
                            '&::placeholder': {
                              color: colors.text.secondary,
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ...interFont, 
                          color: colors.text.secondary,
                          mb: 1,
                          fontWeight: 500 
                        }}
                      >
                        Gateway de Pagamento
                      </Typography>
                      {gateways.length > 0 && !gateways.some(g => g.status === 'ativo') ? (
                        <Box sx={{ mb: 2 }}>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: theme.palette.warning.main,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            <WarningAmberIcon sx={{ fontSize: 20 }} />
                            Você ainda não tem nenhum gateway de pagamento ativo.
                          </Typography>
                          <Button
                            component={RouterLink}
                            to="/integrations"
                            variant="outlined"
                            size="small"
                            sx={{ 
                              mt: 1,
                              borderRadius: '8px',
                              textTransform: 'none',
                              ...interFont
                            }}
                          >
                            Configurar Gateways
                          </Button>
                        </Box>
                      ) : (
                        <TextField
                          fullWidth
                          select
                          name="gateway"
                          value={formData.gateway}
                          onChange={handleInputChange}
                          required
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '10px',
                              '& fieldset': {
                                borderColor: colors.border,
                              },
                              '&:hover fieldset': {
                                borderColor: colors.primary,
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: colors.primary,
                              },
                            },
                            '& .MuiSelect-select': {
                              ...interFont,
                              padding: '12px 14px',
                              color: colors.text.primary,
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <em>Selecione um gateway</em>
                          </MenuItem>
                          {gateways.map((gateway) => (
                            <MenuItem 
                              key={gateway.id} 
                              value={gateway.id}
                              disabled={gateway.status !== 'ativo'}
                            >
                              {gateway.name} {gateway.status !== 'ativo' && `(${gateway.status_text})`}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {formData.gateway && gateways.find(g => g.id === formData.gateway)?.status !== 'ativo' && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: theme.palette.warning.main,
                            mt: 1,
                            display: 'block'
                          }}
                        >
                          Este gateway precisa ser configurado na página de Integrações
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="subtitle1" sx={{ mb: 1, color: colors.text.secondary }}>
                        Selecione o Modelo de Checkout
                      </Typography>
                      <Grid container spacing={2}>
                        {templates.map((template) => (
                          <Grid item xs={12} sm={6} key={template.value}>
                            <Paper
                              elevation={0}
                              onClick={() => !template.disabled && handleInputChange({ target: { name: 'template', value: template.value } })}
                              sx={{
                                p: 2,
                                border: `1px solid ${formData.template === template.value ? colors.primary : colors.border}`,
                                borderRadius: '10px',
                                cursor: template.disabled ? 'not-allowed' : 'pointer',
                                bgcolor: formData.template === template.value ? `${colors.primary}10` : 'transparent',
                                opacity: template.disabled ? 0.6 : 1,
                                '&:hover': {
                                  borderColor: template.disabled ? colors.border : colors.primary,
                                  bgcolor: template.disabled ? 'transparent' : `${colors.primary}10`,
                                },
                                position: 'relative',
                              }}
                            >
                              <Typography sx={{ ...interFont, fontWeight: 500, textAlign: 'center' }}>
                                {template.label}
                              </Typography>
                              {template.disabled && (
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    position: 'absolute',
                                    bottom: 4,
                                    right: 8,
                                    color: colors.text.secondary,
                                    ...interFont
                                  }}
                                >
                                  Em breve
                                </Typography>
                              )}
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Checkout Preview */}
                  <Box sx={{ height: '100%', overflowY: 'auto' }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 2,
                        fontWeight: 500 
                      }}
                    >
                      Preview do Checkout
                    </Typography>
                    <MercadoLivreCheckout
                      cart={{
                        items: [{
                          id: '1',
                          name: formData.title || 'Produto',
                          quantity: 1,
                          price: parseFloat(formData.price) || 0
                        }],
                        total: parseFloat(formData.price) || 0
                      }}
                      onSuccess={() => {}}
                      onError={() => {}}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                {/* Original form content */}
                <Grid container spacing={2.5}>
                  <Grid item xs={12}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 1,
                        fontWeight: 500 
                      }}
                    >
                      Título
                    </Typography>
                    <TextField
                      fullWidth
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      required
                      placeholder="Insira o título do produto"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                        '& input': {
                          ...interFont,
                          padding: '12px 14px',
                          color: colors.text.primary,
                          '&::placeholder': {
                            color: colors.text.secondary,
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 1,
                        fontWeight: 500 
                      }}
                    >
                      Descrição
                    </Typography>
                    <TextField
                      fullWidth
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                      placeholder="Insira a descrição do produto"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          padding: '0px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                        '& textarea': {
                          ...interFont,
                          padding: '12px 14px',
                          color: colors.text.primary,
                          '&::placeholder': {
                            color: colors.text.secondary,
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 1,
                        fontWeight: 500 
                      }}
                    >
                      Preço
                    </Typography>
                    <TextField
                      fullWidth
                      name="price"
                      type="text"
                      value={formatPrice(formData.price)}
                      onChange={handleInputChange}
                      required
                      placeholder="R$ 0,00"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                        '& input': {
                          ...interFont,
                          padding: '12px 14px',
                          color: colors.text.primary,
                          '&::placeholder': {
                            color: colors.text.secondary,
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 1,
                        fontWeight: 500 
                      }}
                    >
                      Tipo
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                        '& .MuiSelect-select': {
                          ...interFont,
                          padding: '12px 14px',
                          color: colors.text.primary,
                        },
                      }}
                    >
                      <MenuItem value="Digital" sx={{ ...interFont }}>Digital</MenuItem>
                      <MenuItem value="Physical" sx={{ ...interFont }}>Físico</MenuItem>
                      <MenuItem value="Service" sx={{ ...interFont }}>Serviço</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 1,
                        fontWeight: 500 
                      }}
                    >
                      URL da Imagem
                    </Typography>
                    <TextField
                      fullWidth
                      name="picture_url"
                      value={formData.picture_url}
                      onChange={handleInputChange}
                      placeholder="https://"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '& fieldset': {
                            borderColor: colors.border,
                          },
                          '&:hover fieldset': {
                            borderColor: colors.primary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: colors.primary,
                          },
                        },
                        '& input': {
                          ...interFont,
                          padding: '12px 14px',
                          color: colors.text.primary,
                          '&::placeholder': {
                            color: colors.text.secondary,
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        ...interFont, 
                        color: colors.text.secondary,
                        mb: 1,
                        fontWeight: 500 
                      }}
                    >
                      Gateway de Pagamento
                    </Typography>
                    {gateways.length > 0 && !gateways.some(g => g.status === 'ativo') ? (
                      <Box sx={{ mb: 2 }}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: theme.palette.warning.main,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <WarningAmberIcon sx={{ fontSize: 20 }} />
                          Você ainda não tem nenhum gateway de pagamento ativo.
                        </Typography>
                        <Button
                          component={RouterLink}
                          to="/integrations"
                          variant="outlined"
                          size="small"
                          sx={{ 
                            mt: 1,
                            borderRadius: '8px',
                            textTransform: 'none',
                            ...interFont
                          }}
                        >
                          Configurar Gateways
                        </Button>
                      </Box>
                    ) : (
                      <TextField
                        fullWidth
                        select
                        name="gateway"
                        value={formData.gateway}
                        onChange={handleInputChange}
                        required
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            '& fieldset': {
                              borderColor: colors.border,
                            },
                            '&:hover fieldset': {
                              borderColor: colors.primary,
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: colors.primary,
                            },
                          },
                          '& .MuiSelect-select': {
                            ...interFont,
                            padding: '12px 14px',
                            color: colors.text.primary,
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione um gateway</em>
                        </MenuItem>
                        {gateways.map((gateway) => (
                          <MenuItem 
                            key={gateway.id} 
                            value={gateway.id}
                            disabled={gateway.status !== 'ativo'}
                          >
                            {gateway.name} {gateway.status !== 'ativo' && `(${gateway.status_text})`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {formData.gateway && gateways.find(g => g.id === formData.gateway)?.status !== 'ativo' && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: theme.palette.warning.main,
                          mt: 1,
                          display: 'block'
                        }}
                      >
                        Este gateway precisa ser configurado na página de Integrações
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="subtitle1" sx={{ mb: 1, color: colors.text.secondary }}>
                      Selecione o Modelo de Checkout
                    </Typography>
                    <Grid container spacing={2}>
                      {templates.map((template) => (
                        <Grid item xs={12} sm={6} key={template.value}>
                          <Paper
                            elevation={0}
                            onClick={() => !template.disabled && handleInputChange({ target: { name: 'template', value: template.value } })}
                            sx={{
                              p: 2,
                              border: `1px solid ${formData.template === template.value ? colors.primary : colors.border}`,
                              borderRadius: '10px',
                              cursor: template.disabled ? 'not-allowed' : 'pointer',
                              bgcolor: formData.template === template.value ? `${colors.primary}10` : 'transparent',
                              opacity: template.disabled ? 0.6 : 1,
                              '&:hover': {
                                borderColor: template.disabled ? colors.border : colors.primary,
                                bgcolor: template.disabled ? 'transparent' : `${colors.primary}10`,
                              },
                              position: 'relative',
                            }}
                          >
                            <Typography sx={{ ...interFont, fontWeight: 500, textAlign: 'center' }}>
                              {template.label}
                            </Typography>
                            {template.disabled && (
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  position: 'absolute',
                                  bottom: 4,
                                  right: 8,
                                  color: colors.text.secondary,
                                  ...interFont
                                }}
                              >
                                Em breve
                              </Typography>
                            )}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2.5, 
          borderTop: `1px solid ${colors.border}`,
          justifyContent: 'flex-end' 
        }}>
          <Button 
            onClick={handleClose}
            sx={{ 
              ...interFont,
              color: colors.text.secondary,
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)'
              }
            }}
          >
            Cancelar
          </Button>
          <Button 
            type="submit"
            variant="contained"
            sx={{
              ...interFont,
              bgcolor: colors.primary,
              '&:hover': {
                bgcolor: colors.primaryHover
              }
            }}
          >
            {initialData ? 'Salvar Alterações' : 'Adicionar Produto'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ProductForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

const Products = () => {
  const theme = useTheme();
  const colors = getThemeColors(theme);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout } = useAuth();

  // Toggle product active status
  const handleToggleActive = useCallback(async (productId, currentStatus, product) => {
    try {
      const updatedProduct = {
        ...product,
        active: !currentStatus
      };
      await api.put(`/products/${productId}/`, updatedProduct);
      setProducts(prevProducts =>
        prevProducts.map(p =>
          p.id === productId ? { ...p, active: !currentStatus } : p
        )
      );
    } catch (err) {
      if (err.response?.status === 401) {
        logout();
      } else {
        setError('Falha ao atualizar o status do produto. Tente novamente.');
      }
    }
  }, [logout]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('Input changed:', name, value); // Debug log
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  // Helper function to format type
  const formatType = useCallback((type) => {
    if (!type) return 'Digital';
    return type;
  }, []);

  // Fetch products
  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/products/');
      // Convert type to proper case if it exists
      const formattedProducts = response.data.map(product => ({
        ...product,
        type: formatType(product.type)
      }));
      setProducts(formattedProducts);
      setError(null);
    } catch (err) {
      if (err.response?.status === 401) {
        logout();
      } else {
        setError('Falha ao buscar produtos. Tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  }, [logout, formatType]);

  // Delete product
  const handleDelete = useCallback(async (productId) => {
    try {
      await api.delete(`/products/${productId}/`);
      setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
      handleCloseMenu();
    } catch (err) {
      if (err.response?.status === 401) {
        logout();
      } else {
        setError('Falha ao excluir o produto. Tente novamente.');
      }
    }
  }, [logout, handleCloseMenu]);

  // Add/Edit product
  const handleSaveProduct = useCallback(async (productData) => {
    try {
      const dataToSend = {
        title: productData.title,
        description: productData.description,
        price: parseFloat(productData.price),
        picture_url: productData.picture_url,
        type: productData.type || 'Digital',
        template: productData.template,
        gateway: productData.gateway,
      };

      console.log('Sending product data:', dataToSend); // Debug log

      let updatedProduct;
      if (selectedProduct?.id) {
        console.log('Updating product:', selectedProduct.id); // Debug log
        const response = await api.put(`/products/${selectedProduct.id}/`, dataToSend);
        console.log('Update response:', response.data); // Debug log
        updatedProduct = {
          ...response.data,
          type: formatType(response.data.type)
        };
        setProducts(prevProducts => 
          prevProducts.map(p => p.id === selectedProduct.id ? updatedProduct : p)
        );
      } else {
        console.log('Creating new product'); // Debug log
        const response = await api.post('/products/', dataToSend);
        console.log('Create response:', response.data); // Debug log
        updatedProduct = {
          ...response.data,
          type: formatType(response.data.type)
        };
        setProducts(prevProducts => [...prevProducts, updatedProduct]);
      }
      setOpenDialog(false);
      setSelectedProduct(null);
      setError(null);
      fetchProducts(); // Refresh the products list after saving
    } catch (err) {
      console.error('Error saving product:', err.response?.data || err.message);
      if (err.response?.status === 401) {
        logout();
      } else {
        setError(err.response?.data?.detail || 'Falha ao salvar o produto. Tente novamente.');
      }
    }
  }, [selectedProduct, logout, formatType, fetchProducts]);

  const handleMenuOpen = useCallback((event, product) => {
    setAnchorEl(event.currentTarget);
    setSelectedProduct(product);
    setOpenDialog(false); // Ensure dialog is closed when opening menu
  }, []);

  const handleEdit = useCallback(() => {
    handleCloseMenu();
    setOpenDialog(true); // Open dialog only when edit is clicked
  }, [handleCloseMenu]);

  const handleAddNew = useCallback(() => {
    setSelectedProduct(null); 
    setOpenDialog(true);
  }, []);

  // Get icon based on product type
  const getTypeIcon = useCallback((type) => {
    if (!type) return <Package size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />;
    
    switch (type.toLowerCase()) {
      case 'digital':
        return <Cloud size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />;
      case 'physical':
        return <Package size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />;
      case 'service':
        return <Tag size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />;
      default:
        return <Package size={20} color={theme.palette.mode === 'dark' ? '#fff' : '#000'} />;
    }
  }, [theme.palette.mode]);

  // Get color based on product type
  const getTypeColor = useCallback((type) => {
    return theme.palette.mode === 'dark' ? '#fff' : '#000';
  }, [theme.palette.mode]);

  const getCheckoutUrl = useCallback((productId) => {
    return `${window.location.origin}/checkout/${productId}`;
  }, []);

  const handleCopyCheckoutUrl = useCallback((productId) => {
    const checkoutUrl = getCheckoutUrl(productId);
    navigator.clipboard.writeText(checkoutUrl).then(() => {
      console.log('URL de checkout copiada para a área de transferência!');
    });
  }, [getCheckoutUrl]);

  const renderProductDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return (
      <Typography variant="body2" sx={{ fontFamily: '"Inter", sans-serif', color: 'text.secondary' }}>
        Criado em: {formattedDate}
      </Typography>
    );
  };

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  // Filter products based on search
  const filteredProducts = products.filter(product => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (product.title?.toLowerCase() || '').includes(searchTermLower) ||
      (product.description?.toLowerCase() || '').includes(searchTermLower) ||
      (product.type?.toLowerCase() || '').includes(searchTermLower)
    );
  });

  if (loading) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <Typography>Carregando produtos...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: '30px', bgcolor: colors.background, minHeight: '100vh' }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            ...interFont,
            color: colors.text.primary,
            mb: 1,
            fontSize: '25px'
          }}
        >
          Produtos
        </Typography>
        <Typography sx={{ ...interFont, color: colors.text.secondary }}>
          Gerencie seus produtos digitais e serviços
        </Typography>
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <TextField
          placeholder="Buscar produtos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={20} color={colors.text.secondary} />
              </InputAdornment>
            ),
            sx: {
              ...interFont,
              height: '44px',
              borderRadius: '10px',
              backgroundColor: colors.background,
              '& input': {
                padding: '12px 12px 12px 0',
                color: colors.text.primary,
              },
            },
          }}
          sx={{
            flex: 1,
            '& .MuiOutlinedInput-root': {
              backgroundColor: colors.background,
              '& fieldset': {
                borderColor: colors.border,
              },
              '&:hover fieldset': {
                borderColor: colors.primary,
              },
              '&.Mui-focused fieldset': {
                borderColor: colors.primary,
              },
            },
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setSelectedProduct(null);
            setOpenDialog(true);
          }}
          startIcon={<Plus size={20} />}
          sx={{
            ...interFont,
            height: '44px',
            bgcolor: colors.primary,
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            '&:hover': { bgcolor: colors.primaryHover },
          }}
        >
          Adicionar Produto
        </Button>
      </Box>

      <Paper 
        elevation={0}
        sx={{ 
          bgcolor: theme.palette.background.paper, 
          borderRadius: '10px', 
          border: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
          overflow: 'hidden',
          boxShadow: 'none'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            bgcolor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          {/* Actions - centered for icon */}
          <Box sx={{ width: 60, pr: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Ações
            </Typography>
          </Box>

          {/* Product */}
          <Box sx={{ flex: 1, pr: 3 }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Produto
            </Typography>
          </Box>
          {/* Type - center aligned like badge */}
          <Box sx={{ width: 120, pr: 6, display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Tipo
            </Typography>
          </Box>

          {/* Link */}
          <Box sx={{ flex: 1, pr: 3 }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Link
            </Typography>
          </Box>
          {/* Status - left aligned */}
          <Box sx={{ width: 80, display: 'flex', justifyContent: 'flex-start' }}>
            <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 600, fontSize: '0.875rem' }}>
              Status
            </Typography>
          </Box>
        </Box>

        {filteredProducts
          .filter(product => 
            product.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((product, index) => (
            <Box key={product.id} sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              p: 2, 
              borderBottom: theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
              bgcolor: theme.palette.background.paper,
              '&:hover': {
                bgcolor: theme.palette.action.hover,
              },
            }}>
              {/* Actions */}
              <Box sx={{ width: 60, pr: 2 }}>
                <IconButton
                  onClick={(e) => handleMenuOpen(e, product)}
                  sx={{
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      bgcolor: `${theme.palette.primary.main}15`,
                    },
                  }}
                >
                  <MoreVertical size={20} />
                </IconButton>
              </Box>

              {/* Product Icon */}
              <Box sx={{ width: 40, display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: `${theme.palette.primary.main}15`,
                  }}
                >
                  {getTypeIcon(product.type)}
                </Box>
              </Box>

              {/* Product Name and ID */}
              <Box sx={{ flex: 1, pr: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography sx={{ ...interFont, color: theme.palette.text.primary, fontWeight: 500 }}>
                  {product.title}
                </Typography>
                {renderProductDate(product.created_at)}
              </Box>

              {/* Product Type */}
              <Box sx={{ width: 120, pr: 6 }}>
                <Typography
                  sx={{
                    ...interFont,
                    color: theme.palette.text.secondary,
                    px: 2,
                    py: 0.5,
                    bgcolor: `${theme.palette.primary.main}10`,
                    borderRadius: 1,
                    fontSize: '0.875rem',
                    textAlign: 'center',
                  }}
                >
                  {product.type}
                </Typography>
              </Box>

              {/* Checkout Link */}
              <Box sx={{ flex: 1, pr: 3 }}>
                <Typography
                  component={RouterLink}
                  to={getCheckoutUrl(product.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    ...interFont,
                    color: theme.palette.primary.main,
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'block',
                    '&:hover': {
                      color: theme.palette.primary.dark,
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {getCheckoutUrl(product.id)}
                </Typography>
              </Box>

              {/* Status */}
              <Box sx={{ width: 80 }}>
                <Toggle
                  checked={product.active}
                  onChange={() => handleToggleActive(product.id, product.active, product)}
                  size="small"
                />
              </Box>
            </Box>
          ))}
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          },
        }}
      >
        <MenuItem
          onClick={handleEdit}
          sx={{
            ...interFont,
            color: theme.palette.text.primary,
            '&:hover': { bgcolor: theme.palette.action.hover },
          }}
        >
          <Edit size={16} style={{ marginRight: '8px' }} />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(selectedProduct.id);
            handleCloseMenu();
          }}
          sx={{
            ...interFont,
            color: '#F04438',
            '&:hover': { bgcolor: '#FEF3F2' },
          }}
        >
          <Trash size={16} style={{ marginRight: '8px' }} />
          Excluir
        </MenuItem>
      </Menu>

      <ProductForm
        open={openDialog}
        handleClose={handleDialogClose}
        handleSubmit={handleSaveProduct}
        initialData={selectedProduct}
      />
    </Box>
  );
};

Products.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Products;
