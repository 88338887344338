import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormControl,
  Button,
  Link,
} from '@mui/material';
import { Shield, CreditCard } from '@phosphor-icons/react';

const nunitoSansFont = {
  fontFamily: '"Nunito Sans", sans-serif',
};

const inputStyles = {
  '& .MuiInputBase-input': {
    ...nunitoSansFont,
    fontSize: '15px',
    height: '40px',
    padding: '0 14px',
    '&::placeholder': {
      fontSize: '13px',
      opacity: 1,
    },
  },
  '& .MuiInputBase-root': {
    ...nunitoSansFont,
    height: '40px',
  },
  '& .MuiFormLabel-root': {
    ...nunitoSansFont,
  },
  '& .MuiOutlinedInput-root': {
    '& input': {
      '&::placeholder': {
        fontSize: '13px',
        opacity: 1,
      },
    },
  },
};

// Custom PIX icon component
const PixIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4 1.4L12 0L10.6 1.4L9.2 0L7.8 1.4L6.4 0L5 1.4L3.6 0L2.2 1.4L0.8 0V16.8L2.2 15.4L3.6 16.8L5 15.4L6.4 16.8L7.8 15.4L9.2 16.8L10.6 15.4L12 16.8L13.4 15.4L14.8 16.8L16.2 15.4L17.6 16.8L19 15.4L20.4 16.8L21.8 15.4L23.2 16.8V0L21.8 1.4L20.4 0L19 1.4L17.6 0L16.2 1.4L14.8 0L13.4 1.4ZM3.2 13.6L4.6 12.2L6 13.6L7.4 12.2L8.8 13.6L10.2 12.2L11.6 13.6L13 12.2L14.4 13.6L15.8 12.2L17.2 13.6L18.6 12.2L20 13.6L20.8 12.8V3.2L20 4L18.6 2.6L17.2 4L15.8 2.6L14.4 4L13 2.6L11.6 4L10.2 2.6L8.8 4L7.4 2.6L6 4L4.6 2.6L3.2 4L2.4 3.2V12.8L3.2 13.6Z" fill="currentColor"/>
    <path d="M6.4 5.6H8.8V8H6.4V5.6ZM6.4 9.6H8.8V12H6.4V9.6ZM10.4 5.6H12.8V8H10.4V5.6ZM10.4 9.6H12.8V12H10.4V9.6ZM14.4 5.6H16.8V8H14.4V5.6ZM14.4 9.6H16.8V12H14.4V9.6Z" fill="currentColor"/>
  </svg>
);

const HotmartCheckout = ({ cart }) => {
  const [buyerData, setBuyerData] = useState({
    fullName: '',
    email: '',
    emailConfirmation: '',
    document: '',
    phone: '',
  });
  const [paymentMethod, setPaymentMethod] = useState('credit');
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [installments, setInstallments] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  if (!cart || !cart.items || cart.items.length === 0) {
    return null;
  }

  const product = cart.items[0];
  const price = product.price;
  const installmentPrice = price / 12;

  const handleBuyerDataChange = (field) => (event) => {
    setBuyerData({ ...buyerData, [field]: event.target.value });
  };

  return (
    <Box sx={{ 
      bgcolor: '#f8f9fc', 
      minHeight: '100vh', 
      py: {
        xs: 0,
        sm: 4,
      }, 
      ...nunitoSansFont 
    }}>
      <Container
        maxWidth={false}
        sx={{
          width: {
            xs: '100%',
            sm: '670px',
          },
          mx: 'auto',
          py: 4,
          px: {
            xs: 0,
            sm: 0,
          },
        }}
      >
        <Paper 
          elevation={0} 
          sx={{ 
            p: {
              xs: '20px',
              sm: 3,
            },
            border: '1px solid #e6e6f0',
            borderRadius: {
              xs: 0,
              sm: 1,
            },
          }}
        >
          {/* Security Badge and Brazil Flag */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Shield size={20} weight="fill" color="#4CAF50" />
              <Typography 
                variant="caption" 
                sx={{ 
                  fontSize: '12px',
                  fontWeight: 700,
                  color: '#191c1f',
                  ...nunitoSansFont,
                }}
              >
                COMPRA 100% SEGURA
              </Typography>
            </Box>
            <img 
              src="/brazil-flag.svg" 
              alt="Brazil Flag" 
              style={{ 
                width: '24px',
                height: '16px',
              }} 
            />
          </Box>
          <Divider sx={{ mb: 3 }} />

          {/* Product Info */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item>
              <Box
                component="img"
                src={product.picture_url || 'https://via.placeholder.com/80'}
                alt={product.title}
                sx={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 1, ...nunitoSansFont }}>
                {product.title}
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: '#f04e23',
                  fontWeight: 600,
                  mb: 0.5,
                  ...nunitoSansFont,
                }}
              >
                12x de R$ {installmentPrice.toFixed(2)}
              </Typography>
              <Typography 
                variant="body2"
                sx={{ 
                  color: '#f04e23',
                  ...nunitoSansFont,
                }}
              >
                Ou R$ {price.toFixed(2)} à vista
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          {/* Buyer Information */}
          <Box sx={{ mt: 3 }}>
            <Typography 
              variant="body2" 
              sx={{ 
                mb: 1, 
                fontWeight: 500,
                fontSize: '12px',
                ...nunitoSansFont,
              }}
            >
              Nome Completo
            </Typography>
            <TextField
              fullWidth
              placeholder="Digite seu nome completo"
              value={buyerData.fullName}
              onChange={handleBuyerDataChange('fullName')}
              sx={{ mb: 3, ...inputStyles }}
            />

            <Typography 
              variant="body2" 
              sx={{ 
                mb: 1, 
                fontWeight: 500,
                fontSize: '12px',
                ...nunitoSansFont,
              }}
            >
              Seu email
            </Typography>
            <TextField
              fullWidth
              placeholder="Digite seu email para receber a compra"
              value={buyerData.email}
              onChange={handleBuyerDataChange('email')}
              sx={{ mb: 3, ...inputStyles }}
            />

            <Typography 
              variant="body2" 
              sx={{ 
                mb: 1, 
                fontWeight: 500,
                fontSize: '12px',
                ...nunitoSansFont,
              }}
            >
              Confirme seu email
            </Typography>
            <TextField
              fullWidth
              placeholder="Digite novamente seu email"
              value={buyerData.emailConfirmation}
              onChange={handleBuyerDataChange('emailConfirmation')}
              sx={{ mb: 3, ...inputStyles }}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 1, 
                    fontWeight: 500,
                    fontSize: '12px',
                    ...nunitoSansFont,
                  }}
                >
                  CPF/CNPJ
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Digite seu número de CPF/CNPJ"
                  value={buyerData.document}
                  onChange={handleBuyerDataChange('document')}
                  sx={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 1, 
                    fontWeight: 500,
                    fontSize: '12px',
                    ...nunitoSansFont,
                  }}
                >
                  Celular
                </Typography>
                <TextField
                  fullWidth
                  placeholder="(19) 96703-1234"
                  value={buyerData.phone}
                  onChange={handleBuyerDataChange('phone')}
                  sx={inputStyles}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <img 
                            src="https://flagcdn.com/w20/br.png" 
                            alt="Brazil" 
                            style={{ width: '15px', height: 'auto' }}
                          />
                          +55
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Payment Method Selection */}
          <Box sx={{ mt: 4 }}>
            <RadioGroup
              value={paymentMethod}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0,
              }}
            >
              <Paper
                elevation={0}
                onClick={() => setPaymentMethod('credit')}
                sx={{
                  border: '1px solid',
                  borderColor: paymentMethod === 'credit' ? '#f04e23' : '#e6e6f0',
                  borderRadius: '4px 0px 0px 4px',
                  borderRight: 'none',
                  p: 2,
                  flex: 1,
                  cursor: 'pointer',
                  bgcolor: paymentMethod === 'credit' ? '#fff6f4' : 'transparent',
                  '&:hover': {
                    borderColor: '#f04e23',
                    borderRight: '1px solid #f04e23',
                    marginRight: '0px',
                  },
                }}
              >
                <FormControlLabel
                  value="credit"
                  control={
                    <Radio 
                      sx={{
                        display: 'none',
                      }}
                    />
                  }
                  label={
                    <Box sx={{ textAlign: 'center' }}>
                      <CreditCard 
                        size={24} 
                        weight={paymentMethod === 'credit' ? 'fill' : 'regular'}
                        color={paymentMethod === 'credit' ? '#f04e23' : '#6e7179'}
                      />
                      <Typography 
                        sx={{ 
                          mt: 1,
                          fontSize: '12px',
                          fontWeight: 700,
                          color: paymentMethod === 'credit' ? '#f04e23' : '#6e7179',
                          ...nunitoSansFont,
                        }}
                      >
                        Cartão de Crédito
                      </Typography>
                    </Box>
                  }
                  sx={{
                    m: 0,
                    width: '100%',
                    '& .MuiFormControlLabel-label': {
                      width: '100%',
                    },
                  }}
                />
              </Paper>

              <Paper
                elevation={0}
                onClick={() => setPaymentMethod('pix')}
                sx={{
                  border: '1px solid',
                  borderColor: paymentMethod === 'pix' ? '#f04e23' : '#e6e6f0',
                  borderRadius: '0px 4px 4px 0px',
                  p: 2,
                  flex: 1,
                  cursor: 'pointer',
                  bgcolor: paymentMethod === 'pix' ? '#fff6f4' : 'transparent',
                  '&:hover': {
                    borderColor: '#f04e23',
                  },
                }}
              >
                <FormControlLabel
                  value="pix"
                  control={
                    <Radio 
                      sx={{
                        display: 'none',
                      }}
                    />
                  }
                  label={
                    <Box sx={{ textAlign: 'center' }}>
                      <Box sx={{ 
                        color: paymentMethod === 'pix' ? '#f04e23' : '#6e7179',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                        <PixIcon />
                      </Box>
                      <Typography 
                        sx={{ 
                          mt: 1,
                          fontSize: '12px',
                          fontWeight: 700,
                          color: paymentMethod === 'pix' ? '#f04e23' : '#6e7179',
                          ...nunitoSansFont,
                        }}
                      >
                        PIX
                      </Typography>
                    </Box>
                  }
                  sx={{
                    m: 0,
                    width: '100%',
                    '& .MuiFormControlLabel-label': {
                      width: '100%',
                    },
                  }}
                />
              </Paper>
            </RadioGroup>

            {/* Credit Card Form */}
            {paymentMethod === 'credit' && (
              <Box 
                sx={{ 
                  mt: 2,
                  p: '15px',
                  border: '1px solid #e6e6f0',
                  borderRadius: 1,
                }}
              >
                {/* Card Number */}
                <FormControl fullWidth>
                  <Typography 
                    sx={{ 
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1c1c',
                      mb: 1,
                      ...nunitoSansFont,
                    }}
                  >
                    Número do cartão
                  </Typography>
                  <OutlinedInput
                    placeholder="Digite somente números"
                    notched={false}
                    endAdornment={
                      <InputAdornment position="end">
                        <CreditCard size={20} />
                      </InputAdornment>
                    }
                    sx={{
                      height: '40px',
                      '& input': {
                        fontSize: '15px',
                        ...nunitoSansFont,
                      },
                      '& input::placeholder': {
                        fontSize: '13px',
                        color: '#6e7179',
                      },
                    }}
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                  />
                </FormControl>

                {/* Card Holder Name */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Typography 
                    sx={{ 
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1c1c',
                      mb: 1,
                      ...nunitoSansFont,
                    }}
                  >
                    Nome do titular
                  </Typography>
                  <OutlinedInput
                    placeholder="Digite o nome impresso no cartão"
                    notched={false}
                    sx={{
                      height: '40px',
                      '& input': {
                        fontSize: '15px',
                        ...nunitoSansFont,
                      },
                      '& input::placeholder': {
                        fontSize: '13px',
                        color: '#6e7179',
                      },
                    }}
                    value={cardHolderName}
                    onChange={(e) => setCardHolderName(e.target.value)}
                  />
                </FormControl>

                {/* Expiry Date and CVV */}
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  {/* Month */}
                  <FormControl sx={{ flex: 1 }}>
                    <Typography 
                      sx={{ 
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#1c1c1c',
                        mb: 1,
                        ...nunitoSansFont,
                      }}
                    >
                      Mês
                    </Typography>
                    <Select
                      displayEmpty
                      placeholder="MM"
                      notched={false}
                      sx={{
                        height: '40px',
                        '& .MuiSelect-select': {
                          fontSize: '15px',
                          ...nunitoSansFont,
                        },
                      }}
                      value={expiryMonth}
                      onChange={(e) => setExpiryMonth(e.target.value)}
                    >
                      <MenuItem disabled value="">
                        <Typography sx={{ fontSize: '13px', color: '#6e7179', ...nunitoSansFont }}>
                          MM
                        </Typography>
                      </MenuItem>
                      {[...Array(12)].map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {String(i + 1).padStart(2, '0')}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Year */}
                  <FormControl sx={{ flex: 1 }}>
                    <Typography 
                      sx={{ 
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#1c1c1c',
                        mb: 1,
                        ...nunitoSansFont,
                      }}
                    >
                      Ano
                    </Typography>
                    <Select
                      displayEmpty
                      placeholder="AA"
                      notched={false}
                      sx={{
                        height: '40px',
                        '& .MuiSelect-select': {
                          fontSize: '15px',
                          ...nunitoSansFont,
                        },
                      }}
                      value={expiryYear}
                      onChange={(e) => setExpiryYear(e.target.value)}
                    >
                      <MenuItem disabled value="">
                        <Typography sx={{ fontSize: '13px', color: '#6e7179', ...nunitoSansFont }}>
                          AA
                        </Typography>
                      </MenuItem>
                      {[...Array(34)].map((_, i) => (
                        <MenuItem key={i + 24} value={i + 24}>
                          {i + 24}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* CVV */}
                  <FormControl sx={{ flex: 1 }}>
                    <Typography 
                      sx={{ 
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#1c1c1c',
                        mb: 1,
                        ...nunitoSansFont,
                      }}
                    >
                      CVV
                    </Typography>
                    <OutlinedInput
                      placeholder="CVV"
                      notched={false}
                      sx={{
                        height: '40px',
                        '& input': {
                          fontSize: '15px',
                          ...nunitoSansFont,
                        },
                        '& input::placeholder': {
                          fontSize: '13px',
                          color: '#6e7179',
                        },
                      }}
                      value={cvv}
                      onChange={(e) => setCvv(e.target.value)}
                    />
                  </FormControl>
                </Box>

                {/* Installments */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Typography 
                    sx={{ 
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1c1c',
                      mb: 1,
                      ...nunitoSansFont,
                    }}
                  >
                    Selecione o número de parcelas
                  </Typography>
                  <Select
                    displayEmpty
                    notched={false}
                    sx={{
                      height: '40px',
                      '& .MuiSelect-select': {
                        fontSize: '15px',
                        ...nunitoSansFont,
                      },
                    }}
                    value={installments}
                    onChange={(e) => setInstallments(e.target.value)}
                  >
                    {[...Array(12)].map((_, i) => {
                      const installment = i + 1;
                      const installmentValue = price / installment;
                      return (
                        <MenuItem key={installment} value={installment}>
                          {`${installment}x de R$ ${installmentValue.toFixed(2)}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>

          {/* Payment Button and Info */}
          <Box sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="contained"
              disabled={loading}
              onClick={async () => {
                console.log("=== Payment Button Clicked ===");
                console.log("Payment Method:", paymentMethod);
                console.log("Buyer Data:", buyerData);
                
                try {
                  setLoading(true);
                  setError(null);

                  const payload = {
                    card_data: {
                      number: cardNumber,
                      cardholder_name: cardHolderName,
                      expiry_month: expiryMonth,
                      expiry_year: expiryYear,
                      security_code: cvv
                    },
                    customer_data: {
                      name: buyerData.fullName,
                      email: buyerData.email,
                      phone: buyerData.phone,
                      cpf: buyerData.document,
                      address: {
                        street: "Not provided", // These are required by the serializer
                        number: "N/A",
                        neighborhood: "Not provided",
                        city: "Not provided",
                        state: "Not provided",
                        zip_code: "00000000"
                      }
                    }
                  };

                  const response = await axios.post(
                    `/api/transactions/${product.id}/process_payment/`,
                    payload,
                    {
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    }
                  );

                  if (response.data.success) {
                    setPaymentStatus(response.data.status);
                    setLoading(false);
                    setError(null);
                  } else {
                    setError('Erro ao processar pagamento');
                    setLoading(false);
                  }
                } catch (err) {
                  console.error("Payment processing failed:", err);
                  setError(err.response?.data?.detail || 'Failed to process payment. Please try again.');
                } finally {
                  setLoading(false);
                }
              }}
              sx={{
                bgcolor: '#009d43',
                height: '48px',
                borderRadius: '5px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#008939',
                },
                ...nunitoSansFont,
              }}
            >
              {loading ? 'Processando...' : 'Comprar Agora'}
            </Button>
            {loading ? (
              <div className="loading-spinner">Processando...</div>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : paymentStatus ? (
              <div className="success-message">
                {paymentStatus === 'approved' ? 'Pagamento Aprovado' : 
                 paymentStatus === 'pending' ? 'Processando pagamento' : 
                 'Status: ' + paymentStatus}
              </div>
            ) : (
              <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}

            {/* Support Links */}
            <Box sx={{ mt: 3 }}>
              <Typography 
                sx={{ 
                  fontSize: '10px',
                  color: '#6e7179',
                  ...nunitoSansFont,
                }}
              >
                Tem dúvidas sobre o produto? Entre em contato com
              </Typography>
              <Typography 
                sx={{ 
                  mt: 2,
                  fontSize: '10px',
                  color: '#6e7179',
                  ...nunitoSansFont,
                }}
              >
                Não consegue finalizar esta compra? <Link href="#" sx={{ color: '#f04e23', textDecoration: 'none', fontSize: '10px' }}>Acesse nossa Central de Ajuda</Link>
              </Typography>
            </Box>

            {/* Installment Info */}
            <Typography 
              sx={{ 
                mt: 2,
                fontSize: '10px',
                color: '#6e7179',
                ...nunitoSansFont,
              }}
            >
              *O valor parcelado possui acréscimo.
            </Typography>

            {/* Terms */}
            <Typography 
              sx={{ 
                mt: 2,
                fontSize: '10px',
                color: '#6e7179',
                ...nunitoSansFont,
              }}
            >
              Ao clicar em 'Comprar agora', eu declaro que li e concordo (i) que a Hotmart está processando este pedido em nome de {product?.owner?.username}. Concordando com os <Link href="#" sx={{ color: '#f04e23', textDecoration: 'none', fontSize: '10px' }}>Termos de Uso</Link>, <Link href="#" sx={{ color: '#f04e23', textDecoration: 'none', fontSize: '10px' }}>Política de Privacidade</Link> e (iii) que sou maior de idade ou autorizado e acompanhado por um responsável legal.
            </Typography>

            {/* Disclaimer */}
            <Typography 
              sx={{ 
                mt: 2,
                fontSize: '10px',
                color: '#6e7179',
                ...nunitoSansFont,
              }}
            >
              A instituição Clavo é não se responsabiliza pela entrega dos produtos, somos apenas uma solução de checkout e pagamentos para o mercado digital. Nos isentamos de quaisquer danos a clientes, inferindo ao usuário dono do produto caso necessário.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default HotmartCheckout;
