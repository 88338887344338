import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputAdornment,
  Alert,
  Divider,
  Switch,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { 
  ArrowForward, 
  ArrowBack, 
  ErrorOutline, 
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const isValidCPF = (cpf) => {
  const cpfClean = cpf.replace(/[^\d]/g, '');
  return cpfClean.length === 11;
};

const isValidCNPJ = (cnpj) => {
  const cnpjClean = cnpj.replace(/[^\d]/g, '');
  return cnpjClean.length === 14;
};

const Register = () => {
  const navigate = useNavigate();
  const [isPessoaJuridica, setIsPessoaJuridica] = useState(true);
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    document: '',
    whatsapp: '',
    password: '',
    confirmTerms: false,
  });
  const [fieldErrors, setFieldErrors] = useState({
    full_name: '',
    email: '',
    document: '',
    whatsapp: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ show: false, message: '' });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;
    let progressTimer;

    if (alertInfo.show) {
      // Progress bar animation
      const startTime = Date.now();
      const duration = 3000; // 3 seconds

      progressTimer = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const newProgress = (elapsed / duration) * 100;
        
        if (newProgress >= 100) {
          clearInterval(progressTimer);
          setProgress(0);
        } else {
          setProgress(newProgress);
        }
      }, 10);

      // Hide alert after 3 seconds
      timer = setTimeout(() => {
        setAlertInfo({ show: false, message: '' });
        clearInterval(progressTimer);
        setProgress(0);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(progressTimer);
    };
  }, [alertInfo.show]);

  // Validation functions
  const validateFullName = (name) => {
    const nameRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]/g, '');
    
    if (cnpj.length !== 14) return false;
    
    // Check for repeated numbers
    if (/^(\d)\1+$/.test(cnpj)) return false;
    
    // Validation algorithm
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;
    
    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    return result === parseInt(digits.charAt(1));
  };

  const validateCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    
    if (cpf.length !== 11) return false;
    
    // Check for repeated numbers
    if (/^(\d)\1+$/.test(cpf)) return false;
    
    // Validation algorithm
    let sum = 0;
    let remainder;
    
    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    
    remainder = (sum * 10) % 11;
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;
    
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    
    remainder = (sum * 10) % 11;
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    return remainder === parseInt(cpf.substring(10, 11));
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasCapital = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    
    return {
      isValid: minLength && hasSpecialChar && hasCapital && hasNumber,
      errors: {
        minLength,
        hasSpecialChar,
        hasCapital,
        hasNumber
      }
    };
  };

  // Format functions
  const formatCNPJ = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .substring(0, 18);
  };

  const formatCPF = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .substring(0, 14);
  };

  const formatWhatsApp = (value) => {
    const numbers = value.replace(/\D/g, '');
    let formatted = numbers;
    
    if (numbers.length > 0) {
      if (numbers.length <= 2) {
        formatted = `(${numbers}`;
      } else if (numbers.length <= 7) {
        formatted = `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
      } else if (numbers.length <= 11) {
        formatted = `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7)}`;
      } else {
        formatted = `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7, 11)}`;
      }
    }
    
    return formatted;
  };

  // Input handlers
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    let formattedValue = value;
    let error = '';

    switch (field) {
      case 'full_name':
        if (value && !validateFullName(value)) {
          error = 'Nome deve conter apenas letras';
        }
        break;

      case 'email':
        if (value && !validateEmail(value)) {
          error = 'Email inválido';
        }
        break;

      case 'document':
        if (isPessoaJuridica) {
          formattedValue = formatCNPJ(value);
          if (value && !validateCNPJ(value)) {
            error = 'CNPJ inválido';
          }
        } else {
          formattedValue = formatCPF(value);
          if (value && !validateCPF(value)) {
            error = 'CPF inválido';
          }
        }
        break;

      case 'whatsapp':
        formattedValue = formatWhatsApp(value);
        break;

      case 'password':
        const validation = validatePassword(value);
        if (value && !validation.isValid) {
          error = (
            <div>
              <div style={{ color: validation.errors.minLength ? '#4CAF50' : '#FF4D4F' }}>
                • Mínimo de 8 caracteres
              </div>
              <div style={{ color: validation.errors.hasSpecialChar ? '#4CAF50' : '#FF4D4F' }}>
                • Um caractere especial
              </div>
              <div style={{ color: validation.errors.hasCapital ? '#4CAF50' : '#FF4D4F' }}>
                • Uma letra maiúscula
              </div>
              <div style={{ color: validation.errors.hasNumber ? '#4CAF50' : '#FF4D4F' }}>
                • Um número
              </div>
            </div>
          );
        }
        break;
    }

    setFormData(prev => ({
      ...prev,
      [field]: formattedValue
    }));

    setFieldErrors(prev => ({
      ...prev,
      [field]: error
    }));
  };

  const handleChange = (field) => (event) => {
    handleInputChange(event, field);
  };

  const handlePersonTypeChange = (type) => {
    setIsPessoaJuridica(type === 'PJ');
    // Clear document field and its error when switching person type
    setFormData(prev => ({
      ...prev,
      document: ''
    }));
    setFieldErrors(prev => ({
      ...prev,
      document: ''
    }));
  };

  const validateForm = () => {
    const errors = {
      full_name: '',
      email: '',
      document: '',
      whatsapp: '',
      password: '',
    };

    // Full Name validation
    if (!formData.full_name.trim()) {
      errors.full_name = 'Nome completo é obrigatório';
    }

    // Email validation
    if (!formData.email.trim()) {
      errors.email = 'Email é obrigatório';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Email inválido';
    }

    // Document validation
    const documentClean = formData.document.replace(/\D/g, '');
    if (!documentClean) {
      errors.document = isPessoaJuridica ? 'CNPJ é obrigatório' : 'CPF é obrigatório';
    } else if (isPessoaJuridica && !validateCNPJ(documentClean)) {
      errors.document = 'CNPJ inválido';
    } else if (!isPessoaJuridica && !validateCPF(documentClean)) {
      errors.document = 'CPF inválido';
    }

    // WhatsApp validation
    const whatsappClean = formData.whatsapp.replace(/\D/g, '');
    if (!whatsappClean) {
      errors.whatsapp = 'WhatsApp é obrigatório';
    } else if (whatsappClean.length < 11) {
      errors.whatsapp = 'WhatsApp inválido';
    }

    // Password validation
    if (!formData.password) {
      errors.password = 'Senha é obrigatória';
    } else if (formData.password.length < 8) {
      errors.password = 'A senha deve ter pelo menos 8 caracteres';
    }

    setFieldErrors(errors);
    return !Object.values(errors).some((field) => field);
  };

  const clearForm = () => {
    setFormData({
      full_name: '',
      email: '',
      document: '',
      whatsapp: '',
      password: '',
      confirmTerms: false,
    });
    setFieldErrors({
      full_name: '',
      email: '',
      document: '',
      whatsapp: '',
      password: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await api.post('/register/', {
        full_name: formData.full_name.trim(),
        email: formData.email.trim(),
        document: formData.document.replace(/\D/g, ''),
        whatsapp: formData.whatsapp.replace(/\D/g, ''),
        password: formData.password,
        person_type: isPessoaJuridica ? 'pj' : 'pf',
      });
      navigate('/login');
    } catch (err) {
      console.error('Erro no registro:', err.response?.data);
      const errorData = err.response?.data;
      
      if (errorData) {
        let errorMessage = '';
        
        // Log the error data structure for debugging
        console.log('Estrutura do erro:', JSON.stringify(errorData, null, 2));
        
        if (typeof errorData === 'object') {
          // Check for nested error messages
          if (errorData.email?.[0]) {
            errorMessage = `Este e-mail já está cadastrado em nossa base`;
          } else if (errorData.document?.[0]) {
            errorMessage = `Este ${isPessoaJuridica ? 'CNPJ' : 'CPF'} já está cadastrado em nossa base`;
          } else if (errorData.whatsapp?.[0]) {
            errorMessage = `Este número de WhatsApp já está cadastrado em nossa base`;
          } else if (errorData.non_field_errors?.[0]) {
            errorMessage = errorData.non_field_errors[0];
          } else {
            // If we have an error but not in the expected format
            const firstError = Object.entries(errorData)[0];
            if (firstError) {
              const [field, message] = firstError;
              const fieldMessage = Array.isArray(message) ? message[0] : message;
              
              // Translate field names to Portuguese
              const fieldTranslations = {
                full_name: 'nome completo',
                email: 'e-mail',
                document: isPessoaJuridica ? 'CNPJ' : 'CPF',
                whatsapp: 'WhatsApp',
                password: 'senha'
              };
              
              const translatedField = fieldTranslations[field] || field;
              errorMessage = `Erro no campo ${translatedField}: ${fieldMessage}`;
            } else {
              errorMessage = 'Ocorreu um erro ao criar sua conta. Por favor, tente novamente.';
            }
          }
        } else if (typeof errorData === 'string') {
          errorMessage = errorData;
        }

        if (errorMessage) {
          setAlertInfo({ show: true, message: errorMessage });
          clearForm();
        }
      }
    }
  };

  const inputStyle = (hasError) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& fieldset': {
        borderColor: hasError ? '#FF4D4F' : '#e0e1e4',
      },
      '&:hover fieldset': {
        borderColor: hasError ? '#FF4D4F' : '#e0e1e4',
      },
      '&.Mui-focused fieldset': {
        borderColor: hasError ? '#FF4D4F' : '#0C82CA',
      },
    },
    '& .MuiInputBase-input': {
      color: '#000',
    },
    '& .MuiInputLabel-root': {
      color: hasError ? '#FF4D4F' : '#6B7280',
      '&.Mui-focused': {
        color: hasError ? '#FF4D4F' : '#0C82CA',
      },
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ height: '100vh', bgcolor: '#fff' }}>
      {alertInfo.show && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <Alert
            severity="error"
            sx={{
              borderRadius: 0,
              backgroundColor: '#FEE2E2',
              color: '#DC2626',
              '& .MuiAlert-icon': {
                color: '#DC2626',
              },
            }}
          >
            {alertInfo.message}
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: `${progress}%`,
                height: '2px',
                backgroundColor: '#DC2626',
                transition: 'width 10ms linear',
              }}
            />
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 480,
            mx: 'auto',
            p: 4,
            border: '1px solid #dfe1e4',
            borderRadius: 2,
            bgcolor: '#fff',
          }}
        >
          {/* Logo */}
          <Box sx={{ mb: 5, display: 'flex', justifyContent: 'center' }}>
            <img src="/logo.png" alt="Logo" style={{ height: 32 }} />
          </Box>

          {/* Title */}
          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#000',
              mb: '20px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            Crie sua conta
          </Typography>

          {/* Alert Box */}
          <Alert
            severity="warning"
            sx={{
              width: '100%',
              mb: '20px',
              backgroundColor: '#FEF9C3',
              color: '#854D0E',
              '& .MuiAlert-icon': {
                color: '#854D0E',
              },
              border: '1px solid #FEF08A',
              borderRadius: '8px',
              '& .MuiAlert-message': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            Utilize os mesmos dados presentes no documento oficial
          </Alert>

          {/* Person Type Selection */}
          <Box sx={{ mb: '20px', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: 0,
              }}
            >
              <Button
                fullWidth
                onClick={() => handlePersonTypeChange('PJ')}
                sx={{
                  backgroundColor: isPessoaJuridica ? '#000' : '#fff',
                  color: isPessoaJuridica ? '#fff' : '#000',
                  border: `1px solid ${isPessoaJuridica ? '#000' : '#e0e1e4'}`,
                  borderRight: 0,
                  '&:hover': {
                    backgroundColor: isPessoaJuridica ? '#000' : '#fff',
                    opacity: 0.9,
                  },
                  textTransform: 'none',
                  padding: '10px',
                  borderRadius: '10px',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  flex: 1,
                }}
              >
                Pessoa Jurídica
              </Button>
              <Button
                fullWidth
                onClick={() => handlePersonTypeChange('PF')}
                sx={{
                  backgroundColor: !isPessoaJuridica ? '#000' : '#fff',
                  color: !isPessoaJuridica ? '#fff' : '#000',
                  border: `1px solid ${!isPessoaJuridica ? '#000' : '#e0e1e4'}`,
                  borderLeft: 0,
                  '&:hover': {
                    backgroundColor: !isPessoaJuridica ? '#000' : '#fff',
                    opacity: 0.9,
                  },
                  textTransform: 'none',
                  padding: '10px',
                  borderRadius: '10px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  flex: 1,
                }}
              >
                Pessoa Física
              </Button>
            </Box>
          </Box>

          <form onSubmit={handleSubmit} noValidate>
            {/* Full Name */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Nome completo"
                value={formData.full_name}
                onChange={handleChange('full_name')}
                required
                error={!!fieldErrors.full_name}
                helperText={fieldErrors.full_name}
                sx={inputStyle(!!fieldErrors.full_name)}
              />
            </Box>

            {/* Email */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange('email')}
                required
                error={!!fieldErrors.email}
                helperText={fieldErrors.email}
                sx={inputStyle(!!fieldErrors.email)}
              />
            </Box>

            {/* Document (CPF/CNPJ) */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label={isPessoaJuridica ? "CNPJ" : "CPF"}
                value={formData.document}
                onChange={handleChange('document')}
                required
                error={!!fieldErrors.document}
                helperText={fieldErrors.document}
                sx={inputStyle(!!fieldErrors.document)}
                inputProps={{
                  maxLength: isPessoaJuridica ? 18 : 14,
                }}
              />
            </Box>

            {/* WhatsApp */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Seu WhatsApp"
                value={formData.whatsapp}
                onChange={handleChange('whatsapp')}
                required
                error={!!fieldErrors.whatsapp}
                helperText={fieldErrors.whatsapp}
                sx={inputStyle(!!fieldErrors.whatsapp)}
                inputProps={{
                  maxLength: 15,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src="/brazil-flag.svg"
                        alt="Brazil flag"
                        style={{ width: 24, height: 16, marginRight: 8 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Password */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange('password')}
                required
                error={!!fieldErrors.password}
                helperText={fieldErrors.password}
                sx={inputStyle(!!fieldErrors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Submit Button */}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                py: 1.5,
                backgroundColor: '#000',
                color: '#fff',
                borderRadius: '10px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#000',
                  opacity: 0.9,
                },
              }}
            >
              Criar conta
            </Button>

            {/* Terms */}
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                mb: 3,
                textAlign: 'center',
                color: 'text.secondary',
                fontSize: '0.75rem',
              }}
            >
              Ao realizar seu cadastro, você concorda com os nossos{' '}
              <Link href="#" sx={{ color: 'inherit', textDecoration: 'underline' }}>
                Termos de Uso
              </Link>{' '}
              e nossa{' '}
              <Link href="#" sx={{ color: 'inherit', textDecoration: 'underline' }}>
                Política de privacidade
              </Link>
            </Typography>

            <Divider sx={{ mb: 3 }} />

            {/* Back to Login */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 0.5,
                mt: 2
              }}
            >
              <Link
                component={RouterLink}
                to="/login"
                sx={{
                  color: '#000',
                  textDecoration: 'none',
                  fontWeight: 400,
                  fontSize: '15px',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Voltar para o login
              </Link>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
