import React from 'react';
import { Box } from '@mui/material';

const Toggle = ({ checked, onChange, size = 'medium' }) => {
  const sizes = {
    small: {
      width: 32,
      height: 18,
      circle: 14,
    },
    medium: {
      width: 44,
      height: 22,
      circle: 18,
    },
    large: {
      width: 56,
      height: 28,
      circle: 24,
    }
  };

  const { width, height, circle } = sizes[size];

  return (
    <Box
      onClick={onChange}
      sx={{
        position: 'relative',
        display: 'inline-block',
        width,
        height,
        backgroundColor: checked ? '#0067e8' : '#E4E7EC',
        borderRadius: height,
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          opacity: 0.9,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: (height - circle) / 2,
          left: checked ? width - circle - ((height - circle) / 2) : (height - circle) / 2,
          width: circle,
          height: circle,
          backgroundColor: '#FFFFFF',
          borderRadius: '50%',
          transition: 'left 0.3s ease',
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        }}
      />
    </Box>
  );
};

export default Toggle;
