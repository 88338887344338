import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  InputAdornment,
  IconButton,
  Divider,
  useTheme,
} from '@mui/material';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { 
  Visibility, 
  VisibilityOff, 
  ArrowForward,
  ErrorOutline 
} from '@mui/icons-material';
import api from '../services/api';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    email: { error: false, message: '' },
    password: { error: false, message: '' }
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const from = location.state?.from?.pathname || '/';

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    
    const newFieldErrors = {
      email: { error: false, message: '' },
      password: { error: false, message: '' }
    };

    let hasErrors = false;

    // Email validation
    if (!email.trim()) {
      newFieldErrors.email = { error: true, message: 'Email inválido' };
      hasErrors = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newFieldErrors.email = { error: true, message: 'Email inválido' };
      hasErrors = true;
    }

    // Password validation
    if (!password.trim()) {
      newFieldErrors.password = { error: true, message: 'Senha inválida' };
      hasErrors = true;
    }

    setFieldErrors(newFieldErrors);

    if (hasErrors) {
      return false; // Stop here if there are validation errors
    }

    try {
      const response = await api.post('/login/', {
        email: email.trim(),
        password: password,
      });

      if (response.data && response.data.token && response.data.user) {
        login(response.data.token, response.data.user);
        navigate(from, { replace: true });
        return true;
      } else {
        console.error('Invalid server response:', response.data);
        setFieldErrors({
          email: { error: true, message: 'Email ou senha incorretos' },
          password: { error: true, message: 'Email ou senha incorretos' }
        });
        return false;
      }
    } catch (err) {
      console.error('Login error:', err);
      
      if (err.response?.status === 401) {
        setFieldErrors({
          email: { error: true, message: 'Email ou senha incorretos' },
          password: { error: true, message: 'Email ou senha incorretos' }
        });
      } else if (err.response) {
        setFieldErrors({
          email: { error: true, message: 'Erro ao fazer login' },
          password: { error: true, message: 'Erro ao fazer login' }
        });
      } else if (err.request) {
        setFieldErrors({
          email: { error: true, message: 'Erro de conexão' },
          password: { error: true, message: 'Erro de conexão' }
        });
      } else {
        setFieldErrors({
          email: { error: true, message: 'Erro desconhecido' },
          password: { error: true, message: 'Erro desconhecido' }
        });
      }
      return false;
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const inputStyle = (isError) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& fieldset': {
        borderColor: isError ? '#FF4D4F' : theme.palette.divider,
      },
      '&:hover fieldset': {
        borderColor: isError ? '#FF4D4F' : theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: isError ? '#FF4D4F' : theme.palette.primary.main,
      },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
    '& .MuiInputLabel-root': {
      color: isError ? '#FF4D4F' : theme.palette.text.secondary,
      '&.Mui-focused': {
        color: isError ? '#FF4D4F' : theme.palette.primary.main,
      },
    },
  });

  const ErrorMessage = ({ message }) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        mt: 0.5,
        mb: 2,
      }}
    >
      <ErrorOutline sx={{ color: '#FF4D4F', fontSize: 16 }} />
      <Typography
        variant="caption"
        sx={{
          color: '#FF4D4F',
          fontSize: '12px',
          lineHeight: '16px',
        }}
      >
        {message}
      </Typography>
    </Box>
  );

  return (
    <Container maxWidth={false} disableGutters sx={{ 
      height: '100vh', 
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary 
    }}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 480,
            mx: 'auto',
            p: 4,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2,
            bgcolor: theme.palette.background.paper,
          }}
        >
          {/* Logo */}
          <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src="/logo-payment.svg" alt="Clavo Pay" style={{ height: 48 }} />
              <Typography variant="h4" sx={{ 
                ml: 2, 
                fontWeight: 700,
                color: '#000000'
              }}>
                Clavo Pay
              </Typography>
            </Box>
          </Box>

          {/* Subtitle */}
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              color: theme.palette.text.secondary,
              textAlign: 'center',
              maxWidth: '400px',
              mx: 'auto'
            }}
          >
            Checkout transparente com as melhores taxas de conversão
          </Typography>

          <form noValidate onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={inputStyle(fieldErrors.email.error)}
                required
                error={fieldErrors.email.error}
              />
              {fieldErrors.email.error && <ErrorMessage message={fieldErrors.email.message} />}
            </Box>
            
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                error={fieldErrors.password.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePassword}
                        edge="end"
                        sx={{ color: fieldErrors.password.error ? '#FF4D4F' : 'text.secondary' }}
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ fontSize: 20 }} />
                        ) : (
                          <Visibility sx={{ fontSize: 20 }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={inputStyle(fieldErrors.password.error)}
              />
              {fieldErrors.password.error && <ErrorMessage message={fieldErrors.password.message} />}
            </Box>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{
                mt: '10px',
                mb: 2,
                py: 1.5,
                backgroundColor: '#000000',
                color: '#ffffff',
                borderRadius: '10px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
            >
              Entrar
            </Button>

            <Divider sx={{ mb: 3 }} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Link
                component={RouterLink}
                to="/forgot-password"
                sx={{
                  color: '#666666',
                  textDecoration: 'none',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: '#000000',
                    textDecoration: 'underline',
                  },
                }}
              >
                Esqueceu sua senha?
              </Link>

              <Link
                component={RouterLink}
                to="/register"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#666666',
                  textDecoration: 'none',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: '#000000',
                    textDecoration: 'underline',
                  },
                }}
              >
                Crie uma nova conta
                <ArrowForward sx={{ ml: 0.5, fontSize: 16 }} />
              </Link>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
