import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { HotmartCheckout, MercadoLivreCheckout } from './index';
import api from '../../services/api';

const CheckoutTemplate = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}/`);
        setProduct(response.data);
        setError(null);
      } catch (err) {
        setError('Failed to load product details');
        console.error('Error fetching product:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!product) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>Product not found</Typography>
      </Box>
    );
  }

  console.log(product);
  
  if (!product.active) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="100vh"
        sx={{ p: 3 }}
      >
        <Typography 
          variant="h5" 
          color="text.primary" 
          sx={{ 
            fontWeight: 600,
            textAlign: 'center',
            mb: 2
          }}
        >
          Este produto não está mais disponível
        </Typography>
        <Typography 
          color="text.secondary" 
          sx={{ 
            textAlign: 'center'
          }}
        >
          O produto que você está tentando acessar foi desativado ou removido.
        </Typography>
      </Box>
    );
  }

  // Create cart object from product data
  const cart = {
    items: [{
      id: product.id.toString(),
      name: product.title,
      quantity: 1,
      price: parseFloat(product.price)
    }],
    total: parseFloat(product.price)
  };

  // Render the appropriate checkout template based on the product's template setting
  switch (product.template) {
    case 'mercadolivre':
      return <MercadoLivreCheckout cart={cart} />;
    case 'hotmart':
      return <HotmartCheckout cart={cart} />;
    default:
      return <HotmartCheckout cart={cart} />;
  }
};

export default CheckoutTemplate;
