import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';

const Layout = ({ darkMode, setDarkMode }) => {
  const { isAuthenticated } = useAuth();
  const theme = useTheme();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      width: '100%',
      maxWidth: '100%',
      minHeight: '100vh',
      flex: 1,
      bgcolor: theme.palette.background.default,
    }}>
      <Sidebar darkMode={darkMode} setDarkMode={setDarkMode} />
      <Box component="main" sx={{ 
        flexGrow: 1,
        width: '100%',
        maxWidth: '100%',
        bgcolor: theme.palette.background.default,
      }}>
        <Outlet context={[darkMode]} />
      </Box>
    </Box>
  );
};

export default Layout;