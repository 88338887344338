import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Switch,
  Divider,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
  Popover,
} from '@mui/material';
import {
  Home,
  User,
  Package,
  Grid,
  Moon,
  ChevronRight,
  Settings,
  LogOut,
} from 'react-feather';
import { useAuth } from '../context/AuthContext';

const DRAWER_WIDTH = 280;

const Sidebar = ({ darkMode, setDarkMode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    { text: 'Painel', icon: Home, path: '/' },
    { text: 'Produtos', icon: Package, path: '/products' },
    { text: 'Perfil', icon: User, path: '/profile' },
    { text: 'Integrações', icon: Grid, path: '/integrations' },
  ];

  const isActiveRoute = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleProfileClose();
    logout();
  };

  const open = Boolean(anchorEl);

  const sidebarContent = (
    <Box sx={{ 
      width: DRAWER_WIDTH,
      height: '100%',
      bgcolor: darkMode ? '#1A1D1F' : '#fff',
      color: darkMode ? '#fff' : '#1A1D1F',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* Logo Section */}
      <Box sx={{ p: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
        <img src="/logo-payment.svg" alt="Clavo Pay" style={{ height: 32 }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 700,
          ml: 2,
          color: darkMode ? '#FFFFFF' : '#000000'
        }}>
          Clavo Pay
        </Typography>
      </Box>

      {/* Main Menu */}
      <List sx={{ flex: 1, px: 2 }}>
        {menuItems.map((MenuItem) => (
          <ListItem
            key={MenuItem.text}
            button
            onClick={() => handleNavigation(MenuItem.path)}
            sx={{
              mb: 1,
              borderRadius: 2,
              bgcolor: isActiveRoute(MenuItem.path) 
                ? (darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F4F4F4') 
                : 'transparent',
              color: isActiveRoute(MenuItem.path)
                ? (darkMode ? '#fff' : '#000')
                : (darkMode ? '#9A9FA5' : '#6F767E'),
              '&:hover': {
                bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F4F4F4',
              },
            }}
          >
            <ListItemIcon sx={{ 
              color: isActiveRoute(MenuItem.path)
                ? (darkMode ? '#fff' : '#000')
                : (darkMode ? '#9A9FA5' : '#6F767E'),
              minWidth: 40,
            }}>
              <MenuItem.icon size={20} />
            </ListItemIcon>
            <ListItemText 
              primary={MenuItem.text} 
              primaryTypographyProps={{ 
                fontSize: '0.95rem',
                fontWeight: isActiveRoute(MenuItem.path) ? 600 : 400,
              }}
            />
            {isActiveRoute(MenuItem.path) && (
              <ChevronRight size={20} />
            )}
          </ListItem>
        ))}
      </List>

      {/* Bottom Section */}
      <Box sx={{ p: 2 }}>
        <Divider sx={{ mb: 2, borderColor: darkMode ? '#2A2F34' : '#E6E8EC' }} />
        
        {/* Dark Mode Toggle */}
        <ListItem sx={{ 
          mb: 2,
          borderRadius: 2,
          '&:hover': {
            bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F4F4F4',
          },
        }}>
          <ListItemIcon sx={{ 
            color: darkMode ? '#9A9FA5' : '#6F767E',
            minWidth: 40,
          }}>
            <Moon size={20} />
          </ListItemIcon>
          <ListItemText 
            primary="Modo Noturno" 
            primaryTypographyProps={{ 
              fontSize: '0.95rem',
              color: darkMode ? '#9A9FA5' : '#6F767E',
            }}
          />
          <Switch
            checked={darkMode}
            onChange={() => setDarkMode(!darkMode)}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#0C82CA',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#0C82CA',
              },
            }}
          />
        </ListItem>

        {/* Settings */}
        <ListItem
          button
          onClick={() => handleNavigation('/settings')}
          sx={{
            mb: 2,
            borderRadius: 2,
            color: darkMode ? '#9A9FA5' : '#6F767E',
            '&:hover': {
              bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F4F4F4',
            },
          }}
        >
          <ListItemIcon sx={{ 
            color: 'inherit',
            minWidth: 40,
          }}>
            <Settings size={20} />
          </ListItemIcon>
          <ListItemText 
            primary="Configurações" 
            primaryTypographyProps={{ 
              fontSize: '0.95rem',
            }}
          />
        </ListItem>

        {/* User Profile */}
        <ListItem 
          button
          onClick={handleProfileClick}
          sx={{
            borderRadius: 2,
            '&:hover': {
              bgcolor: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#F4F4F4',
            },
          }}
        >
          <Avatar
            src={`https://ui-avatars.com/api/?name=${user?.full_name || user?.email || 'U'}&background=0C82CA&color=fff`}
            sx={{ width: 40, height: 40, mr: 2 }}
          />
          <Box>
            <Typography variant="subtitle2" sx={{ 
              fontWeight: 600, 
              color: darkMode ? '#fff' : '#000',
              fontSize: '0.95rem',
            }}>
              {user?.full_name || user?.email?.split('@')[0] || 'Usuário'}
            </Typography>
            <Typography variant="caption" sx={{ 
              color: darkMode ? '#9A9FA5' : '#6F767E',
              fontSize: '0.85rem',
            }}>
              {user?.email || 'usuário@example.com'}
            </Typography>
          </Box>
        </ListItem>

        {/* User Profile Popover */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleProfileClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              mt: -2,
              ml: 2,
              width: 'auto',
              bgcolor: darkMode ? '#1A1D1F' : '#fff',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
              border: darkMode ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)',
            }
          }}
        >
          <Box sx={{ p: 1 }}>
            <ListItem
              button
              onClick={handleLogoutClick}
              sx={{
                borderRadius: 1,
                color: '#B72C2C',
                '&:hover': {
                  bgcolor: 'rgba(183, 44, 44, 0.1)',
                },
              }}
            >
              <ListItemIcon sx={{ 
                color: 'inherit',
                minWidth: 36,
              }}>
                <LogOut size={18} />
              </ListItemIcon>
              <ListItemText 
                primary="Sair" 
                primaryTypographyProps={{ 
                  fontSize: '0.9rem',
                }}
              />
            </ListItem>
          </Box>
        </Popover>
      </Box>
    </Box>
  );

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          border: 'none',
          boxShadow: '0 0 30px rgba(0, 0, 0, 0.05)',
        },
      }}
      open={!isMobile}
    >
      {sidebarContent}
    </Drawer>
  );
};

export default Sidebar;
