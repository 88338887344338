import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Stack, Typography, ToggleButton, ToggleButtonGroup, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, IconButton } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RefreshCcw, TrendingUp, Eye } from 'react-feather';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../services/api';

// Font styles
const interFont = {
  fontFamily: '"Inter", sans-serif',
};

// Theme colors
const getThemeColors = (darkMode) => ({
  background: darkMode ? '#1A1D1F' : '#f6f8ff',
  text: {
    primary: darkMode ? '#fff' : '#1A1D1F',
    secondary: darkMode ? '#9A9FA5' : '#6F767E',
  }
});

const Dashboard = ({ darkMode }) => {
  const [dashboardData, setDashboardData] = useState({
    salesTotal: 0,
    monthlyTotal: 0,
    refundsTotal: 0,
    revenueData: []
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateFilter, setDateFilter] = useState('last15');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [customButtonPosition, setCustomButtonPosition] = useState(null);
  const [customButtonWidth, setCustomButtonWidth] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const getStatusDisplay = (status) => {
    const statusMap = {
      'failed': 'Rejeitada',
      'rejected': 'Rejeitada',
      'pending': 'Pendente',
      'approved': 'Aprovada',
      'paid': 'Aprovada'
    };
    return statusMap[status] || status;
  };

  const getStatusColor = (status) => {
    if (status === 'failed' || status === 'rejected') return '#FF6B6B';
    if (status === 'approved' || status === 'paid') return '#1CBD72';
    return '#FFA940';
  };

  const getStatusStyle = (status) => {
    const color = getStatusColor(status);
    return {
      backgroundColor: `${color}15`,
      color: color,
      fontWeight: 500,
      fontSize: '0.875rem',
      border: 'none'
    };
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'completed':
        return 'Concluído';
      case 'pending':
        return 'Pendente';
      case 'refunded':
        return 'Reembolsado';
      default:
        return status;
    }
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);

        const params = new URLSearchParams();

        if (dateFilter === 'custom' && startDate && endDate) {
          params.append('start_date', startDate.toISOString().split('T')[0]);
          params.append('end_date', endDate.toISOString().split('T')[0]);
        } else if (dateFilter === 'last15') {
          const date = new Date();
          date.setDate(date.getDate() - 15);
          const startDate = new Date(date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          params.append('start_date', startDate.toISOString().split('T')[0]);
          params.append('end_date', endDate.toISOString().split('T')[0]);
        } else if (dateFilter === 'last30') {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          const startDate = new Date(date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          params.append('start_date', startDate.toISOString().split('T')[0]);
          params.append('end_date', endDate.toISOString().split('T')[0]);
        }

        // Fetch dashboard data and transactions in parallel
        const [dashboardResponse, transactionsResponse] = await Promise.all([
          api.get(`/dashboard/transactions/?${params.toString()}`),
          api.get(`/transactions/?${params.toString()}`)
        ]);

        const { data: dashboardData } = dashboardResponse;
        const { data: transactionsData } = transactionsResponse;

        // Transform dashboard data
        const transformedData = {
          salesTotal: dashboardData.today_sales || 0,
          monthlyTotal: dashboardData.monthly_total || 0,
          refundsTotal: dashboardData.refunds_total || 0,
          revenueData: dashboardData.revenue_data || []
        };

        setDashboardData(transformedData);
        setTransactions(transactionsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [dateFilter, startDate, endDate]);

  const handleToggleChange = (event, newValue) => {
    if (newValue !== null) {
      setDateFilter(newValue);
      if (newValue === 'custom') {
        const button = event.currentTarget;
        const rect = button.getBoundingClientRect();
        setCustomButtonPosition({
          top: rect.bottom + window.scrollY + 8,
          left: rect.left + window.scrollX,
        });
        setOpenDatePicker(true);
      }
    }
  };

  const handleCloseDatePicker = () => {
    if (!startDate || !endDate) {
      setDateFilter('last15');
    }
    setOpenDatePicker(false);
  };

  const handleOpenDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setDetailsModalOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsModalOpen(false);
    setSelectedTransaction(null);
  };

  const StatsCard = ({ title, value, subtitle, Icon, color }) => (
    <Box sx={{
      p: 3,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      bgcolor: darkMode ? '#1A1D1F' : '#FFFFFF',
      border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
      borderRadius: '10px',
    }}>
      <Stack spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: color,
            }}
          >
            <Icon size={20} />
          </Box>
          <Typography 
            variant="body1" 
            sx={{ 
              color: color,
              fontSize: '13px',
              fontWeight: 600,
              letterSpacing: '0.1px',
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: 1,
              letterSpacing: '-0.2px',
            }}
          >
            R${value}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              color: darkMode ? '#9A9FA5' : '#6F767E',
              fontSize: '13px',
              mb: 0.3,
              fontWeight: 500,
              letterSpacing: '0.1px',
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        p: 3,
        height: '100%',
      }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        p: 3,
        height: '100%',
      }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const themeColors = getThemeColors(darkMode);

  return (
    <Box sx={{ 
      p: '30px',
      bgcolor: themeColors.background,
      minHeight: '100vh'
    }}>
      <Container 
        maxWidth="xl"
        disableGutters
        sx={{
          px: 0
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              color: themeColors.text.primary,
              fontSize: '1.1rem',
              fontWeight: 600,
            }}
          >
            Métricas
          </Typography>
          <Typography sx={{ ...interFont, color: themeColors.text.secondary }}>
            Acompanhe suas vendas e métricas
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {/* Left side - Graph */}
          <Grid item xs={12} md={9}>
            <Box sx={{
              p: 3,
              height: '100%',
              minHeight: '410px', // Set minimum height to match cards column
              bgcolor: darkMode ? '#1A1D1F' : '#FFFFFF',
              border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: themeColors.text.primary,
                    fontSize: '1.1rem',
                    fontWeight: 600,
                  }}
                >
                  Faturamento
                </Typography>
                <ToggleButtonGroup
                  value={dateFilter}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Filtro de Data"
                  size="small"
                >
                  <ToggleButton value="last15" aria-label="Últimos 15 dias">
                    15 dias
                  </ToggleButton>
                  <ToggleButton value="last30" aria-label="Últimos 30 dias">
                    30 dias
                  </ToggleButton>
                  <ToggleButton value="custom" aria-label="Personalizado">
                    Personalizado
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box sx={{ flex: 1, width: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={dashboardData.revenueData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      stroke={darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'} 
                      vertical={false}
                    />
                    <XAxis 
                      dataKey="name" 
                      stroke={themeColors.text.secondary}
                      fontSize={12}
                      tickLine={false}
                      axisLine={{ stroke: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)' }}
                      interval={dateFilter === 'custom' ? Math.floor(dashboardData.revenueData.length / 10) : dateFilter === 'last30' ? 1 : 0}
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis 
                      stroke={themeColors.text.secondary}
                      fontSize={12}
                      tickLine={false}
                      axisLine={{ stroke: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)' }}
                      tickFormatter={(value) => `R$${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}
                    />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: darkMode ? '#1A1D1F' : '#fff',
                        border: '1px solid',
                        borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '12px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
                      }}
                      labelStyle={{ 
                        color: themeColors.text.secondary,
                        fontSize: '12px',
                        fontWeight: 500,
                      }}
                      formatter={(value) => [`R$${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`, 'Faturamento']}
                    />
                    <Line
                      type="monotone"
                      dataKey="revenue"
                      stroke="#0C82CA"
                      strokeWidth={2}
                      dot={false}
                      activeDot={{ r: 4, fill: "#0C82CA" }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Grid>

          {/* Right side - Metrics Column */}
          <Grid item xs={12} md={3}>
            <Stack spacing={3} sx={{ height: '100%' }}>
              {/* Revenue Card */}
              <Box sx={{
                py: 2.5,
                px: 2.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#FFFFFF',
                border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                borderRadius: '10px',
                height: '120px', // Fixed height for consistent sizing
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      bgcolor: '#E6F7D9',
                      mr: 2,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: '#1CBD72', fontWeight: 'bold', fontSize: '1rem' }}>R$</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: themeColors.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}>
                      Faturamento Total
                    </Typography>
                    <Typography variant="h6" sx={{ color: themeColors.text.primary, fontSize: '1.25rem', mt: 0.5 }}>
                      R$ {dashboardData.salesTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Monthly Revenue Card */}
              <Box sx={{
                py: 2.5,
                px: 2.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#FFFFFF',
                border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                borderRadius: '10px',
                height: '120px', // Fixed height for consistent sizing
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      bgcolor: '#FFE2E5',
                      mr: 2,
                    }}
                  >
                    <TrendingUp size={20} color="#FF6B6B" />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: themeColors.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}>
                      Faturamento Mensal
                    </Typography>
                    <Typography variant="h6" sx={{ color: themeColors.text.primary, fontSize: '1.25rem', mt: 0.5 }}>
                      R$ {dashboardData.monthlyTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Refunds Card */}
              <Box sx={{
                py: 2.5,
                px: 2.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                bgcolor: darkMode ? '#1A1D1F' : '#FFFFFF',
                border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
                borderRadius: '10px',
                height: '120px', // Fixed height for consistent sizing
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      bgcolor: '#FFF4DE',
                      mr: 2,
                    }}
                  >
                    <RefreshCcw size={20} color="#FFA940" />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: themeColors.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}>
                      Reembolsos
                    </Typography>
                    <Typography variant="h6" sx={{ color: themeColors.text.primary, fontSize: '1.25rem', mt: 0.5 }}>
                      R$ {dashboardData.refundsTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        {/* Transactions List */}
        <Box sx={{ mt: 4 }}>
          <Box sx={{
            p: 3,
            bgcolor: darkMode ? '#1A1D1F' : '#FFFFFF',
            border: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid #eeeeee',
            borderRadius: '10px',
          }}>
            <Typography
              variant="h6"
              sx={{
                color: themeColors.text.primary,
                mb: 3,
                fontSize: '1.1rem',
                fontWeight: 600,
              }}
            >
              Transações Recentes
            </Typography>
            
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Data
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Cliente
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Produto
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Valor
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Gateway
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        color: themeColors.text.secondary,
                        borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        padding: '12px 16px',
                      }}
                    >
                      Detalhes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {new Date(transaction.created_at).toLocaleDateString('pt-BR')} {new Date(transaction.created_at).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {transaction.customer_name || 'N/A'}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        {transaction.product.title}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        R$ {transaction.amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        <Chip 
                          label={getStatusDisplay(transaction.status)}
                          sx={getStatusStyle(transaction.status)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                          textTransform: 'capitalize',
                        }}
                      >
                        {transaction.product.gateway_name || 'N/A'}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          color: themeColors.text.primary,
                          borderBottom: darkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                          padding: '12px 16px',
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleOpenDetails(transaction)}
                          sx={{ color: themeColors.text.secondary }}
                        >
                          <Eye size={18} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {/* Date Picker Modal */}
        <Modal
          open={openDatePicker}
          onClose={handleCloseDatePicker}
          aria-labelledby="date-range-picker"
          BackdropProps={{ invisible: true }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 200,
              p: 2,
              top: customButtonPosition?.top || 0,
              left: (customButtonPosition?.left || 0) - 200 + (customButtonWidth || 0),
              bgcolor: darkMode ? '#1A1D1F' : '#fff',
              color: darkMode ? '#fff' : '#000',
              boxShadow: 3,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={2}>
                <DesktopDatePicker
                  label="Data Inicial"
                  value={startDate}
                  onChange={setStartDate}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          border: 'none',
                          borderRadius: '8px',
                          fontSize: '13px',
                          fontWeight: 500,
                          bgcolor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
                          color: darkMode ? '#fff' : '#000',
                          '& fieldset': { border: 'none' },
                          '&:hover': {
                            bgcolor: darkMode ? 'rgba(255,255,255,0.15)' : 'rgba(0,0,0,0.1)',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: themeColors.text.secondary,
                          fontSize: '13px',
                          fontWeight: 500,
                        }
                      }}
                    />
                  )}
                />
                <DesktopDatePicker
                  label="Data Final"
                  value={endDate}
                  onChange={setEndDate}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          border: 'none',
                          borderRadius: '8px',
                          fontSize: '13px',
                          fontWeight: 500,
                          bgcolor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
                          color: darkMode ? '#fff' : '#000',
                          '& fieldset': { border: 'none' },
                          '&:hover': {
                            bgcolor: darkMode ? 'rgba(255,255,255,0.15)' : 'rgba(0,0,0,0.1)',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: themeColors.text.secondary,
                          fontSize: '13px',
                          fontWeight: 500,
                        }
                      }}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Modal>

        {/* Transaction Details Modal */}
        <Modal
          open={detailsModalOpen}
          onClose={handleCloseDetails}
          aria-labelledby="transaction-details-modal"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '90vh',
            overflow: 'auto'
          }}>
            {selectedTransaction && (
              <>
                <Typography variant="h6" component="h2" gutterBottom>
                  Detalhes da Transação
                </Typography>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">ID da Transação</Typography>
                    <Typography>{selectedTransaction.payment_id}</Typography>
                  </Box>
                  
                  {/* Customer Information */}
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                      Informações do Cliente
                    </Typography>
                    <Stack spacing={2}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Nome</Typography>
                        <Typography>{selectedTransaction.customer_name || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Email</Typography>
                        <Typography>{selectedTransaction.customer_email || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">CPF</Typography>
                        <Typography>{selectedTransaction.customer_cpf || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Telefone</Typography>
                        <Typography>{selectedTransaction.customer_phone || 'N/A'}</Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* Address Information */}
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                      Endereço
                    </Typography>
                    <Stack spacing={2}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Logradouro</Typography>
                        <Typography>{selectedTransaction.customer_address || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Número</Typography>
                        <Typography>{selectedTransaction.customer_address_number || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Complemento</Typography>
                        <Typography>{selectedTransaction.customer_address_complement || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Bairro</Typography>
                        <Typography>{selectedTransaction.customer_neighborhood || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Cidade</Typography>
                        <Typography>{selectedTransaction.customer_city || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Estado</Typography>
                        <Typography>{selectedTransaction.customer_state || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">CEP</Typography>
                        <Typography>{selectedTransaction.customer_zipcode || 'N/A'}</Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* Payment Information */}
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                      Informações do Pagamento
                    </Typography>
                    <Stack spacing={2}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Produto</Typography>
                        <Typography>{selectedTransaction.product.title}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Valor</Typography>
                        <Typography>R$ {selectedTransaction.amount}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Gateway</Typography>
                        <Typography>{selectedTransaction.gateway || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">API Key</Typography>
                        <Typography>{selectedTransaction.masked_api_key || 'N/A'}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                        <Chip 
                          label={getStatusDisplay(selectedTransaction.status)}
                          sx={getStatusStyle(selectedTransaction.status)}
                          size="small"
                        />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary">Data</Typography>
                        <Typography>{new Date(selectedTransaction.created_at).toLocaleString('pt-BR')}</Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* Gateway Response */}
                  {selectedTransaction.gateway_response && (
                    <Box>
                      <Typography variant="subtitle1" gutterBottom fontWeight={600} sx={{ mb: 2 }}>
                        Resposta do Gateway
                      </Typography>
                      <Box sx={{ 
                        bgcolor: darkMode ? 'grey.900' : 'grey.50',
                        p: 2,
                        borderRadius: 1
                      }}>
                        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '0.875rem' }}>
                          {JSON.stringify(selectedTransaction.gateway_response, null, 2)}
                        </pre>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default Dashboard;
