import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';
import Login from './components/Login';
import Register from './components/Register';
import ProductView from './components/ProductView';
import PaymentSuccess from './components/PaymentSuccess';
import Products from './components/Products';
import Profile from './components/Profile';
import Integrations from './components/Integrations';
import { HotmartCheckout } from './components/checkoutTemplates';
import CheckoutTemplate from './components/checkoutTemplates/CheckoutTemplate';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

// Create emotion cache
const cache = createCache({
  key: 'css',
  prepend: true,
});

function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Create theme based on dark mode
  const theme = useMemo(() => createTheme({
    typography: {
      fontFamily: '"Inter", sans-serif',
      h1: { fontWeight: 600 },
      h2: { fontWeight: 600 },
      h3: { fontWeight: 600 },
      h4: { fontWeight: 600 },
      h5: { fontWeight: 600 },
      h6: { fontWeight: 600 },
      button: {
        fontWeight: 500,
        textTransform: 'none',
      },
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0C82CA',
        light: '#3399d9',
        dark: '#096ba8',
      },
      background: {
        default: darkMode ? '#141718' : '#f5f8ff',
        paper: darkMode ? '#1A1D1E' : '#ffffff',
      },
    },
  }), [darkMode]);

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/checkout/:id" element={<CheckoutTemplate />} />
              <Route element={
                <ProtectedRoute>
                  <Layout darkMode={darkMode} setDarkMode={setDarkMode} />
                </ProtectedRoute>
              }>
                <Route index element={<Dashboard darkMode={darkMode} />} />
                <Route path="products" element={<Products />} />
                <Route path="profile" element={<Profile />} />
                <Route path="integrations" element={<Integrations />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
