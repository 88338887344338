import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Button, Avatar, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
import api from '../services/api';

const Profile = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState(null);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get('/user/profile/');
        setUserData(response.data);
      } catch (error) {
        console.error('Erro ao carregar dados do usuário:', error);
      }
    };

    fetchUserData();
  }, []);

  const formatDocument = (doc) => {
    if (!doc) return '';
    if (doc.length === 11) {
      return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (doc.length === 14) {
      return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return doc;
  };

  const formatWhatsApp = (number) => {
    if (!number) return '';
    return number.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('document_file', file);

    try {
      await api.put('/user/profile/', {
        profile: {
          document_file: file
        }
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Refresh user data after upload
      const response = await api.get('/user/profile/');
      setUserData(response.data);
    } catch (error) {
      console.error('Erro ao fazer upload do documento:', error);
    }
  };

  const getVerificationStatusColor = (status) => {
    switch (status) {
      case 'pendente':
        return '#DC2626'; // Red for pending
      case 'enviada':
        return '#D97706'; // Orange for sent
      case 'aceita':
        return '#059669'; // Green for accepted
      default:
        return '#DC2626';
    }
  };

  const getVerificationStatusText = (status) => {
    switch (status) {
      case 'pendente':
        return 'Documentação pendente';
      case 'enviada':
        return 'Documentação enviada';
      case 'aceita':
        return 'Documentação aceita';
      default:
        return 'Documentação pendente';
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const getFileNameFromUrl = (url) => {
    if (!url) return '';
    return url.split('/').pop();
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = getFileNameFromUrl(url);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      
      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Erro ao baixar o documento:', error);
    }
  };

  if (!userData) {
    return <Typography sx={{ color: theme.palette.text.primary }}>Carregando...</Typography>;
  }

  return (
    <Box sx={{ 
      p: { xs: 2, md: 3.75 }, 
      maxWidth: 1200, 
      margin: '0 auto',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default
    }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 4, 
          fontWeight: 500,
          fontSize: '25px',
          color: theme.palette.text.primary
        }}
      >
        Olá, {userData.full_name}!
      </Typography>

      <Grid container spacing={4}>
        {/* Left Column - User Info */}
        <Grid item xs={12} md={5}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              height: '100%',
              borderRadius: '10px',
              backgroundColor: theme.palette.background.paper,
              border: '1px solid #eeeeee'
            }}
          >
            {/* User Profile Header */}
            <Box sx={{ p: 2.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar 
                  sx={{ 
                    width: 64, 
                    height: 64, 
                    bgcolor: theme.palette.primary.main,
                    fontSize: '24px',
                    fontWeight: 500,
                    mr: 2
                  }}
                >
                  {getInitials(userData.full_name)}
                </Avatar>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: theme.palette.text.primary,
                      fontSize: '18px',
                      fontWeight: 600,
                      mb: 0.5
                    }}
                  >
                    {userData.full_name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px'
                    }}
                  >
                    {userData.email}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Information Section */}
            <Box sx={{ p: 2.5, borderTop: '1px solid #E5E7EB' }}>
              {/* User Information List */}
              <Box sx={{ mb: 4 }}>
                {/* Row 1: Verification Status */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mb: 2
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px',
                      fontWeight: 500,
                      width: '100px'
                    }}
                  >
                    Verificação
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{
                      color: getVerificationStatusColor(userData.profile?.documentation_status),
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {getVerificationStatusText(userData.profile?.documentation_status)}
                  </Typography>
                </Box>

                {/* Row 2: Document */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mb: 2
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px',
                      fontWeight: 500,
                      width: '100px'
                    }}
                  >
                    {userData.profile?.person_type === 'pj' ? 'CNPJ' : 'CPF'}
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {userData.profile?.person_type === 'pj' 
                      ? formatDocument(userData.profile?.cnpj)
                      : formatDocument(userData.profile?.cpf)}
                  </Typography>
                </Box>

                {/* Row 3: WhatsApp */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center'
                }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px',
                      fontWeight: 500,
                      width: '100px'
                    }}
                  >
                    WhatsApp
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    {formatWhatsApp(userData.profile?.whatsapp)}
                  </Typography>
                </Box>
              </Box>

              <Button
                variant="outlined"
                fullWidth
                startIcon={<PersonIcon />}
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  borderColor: theme.palette.divider,
                  color: theme.palette.text.primary,
                  fontSize: '14px',
                  fontWeight: 500,
                  p: '10px 16px',
                  '&:hover': {
                    borderColor: theme.palette.action.hover,
                    backgroundColor: theme.palette.action.selected,
                  },
                }}
              >
                Configurar Perfil
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Right Column - Document Upload */}
        <Grid item xs={12} md={7}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 2.5, 
              height: '100%',
              borderRadius: '10px',
              backgroundColor: theme.palette.background.paper,
              border: '1px solid #eeeeee'
            }}
          >
            <Box sx={{ p: 2.5 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 1,
                  color: theme.palette.text.primary,
                  fontSize: '18px',
                  fontWeight: 600
                }}
              >
                Upload de documentos
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  mb: 4,
                  color: theme.palette.text.secondary,
                  fontSize: '14px',
                  lineHeight: 1.5
                }}
              >
                Realize o upload dos seus documentos para começar utilizar a plataforma.
                Documentos incompletos podem fazer com que a sua solicitação seja recusada.
              </Typography>

              {userData.profile?.document_file ? (
                // Display current document
                <Box 
                  sx={{ 
                    mb: 4,
                    p: 3,
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.paper,
                    border: '1px solid #E5E7EB'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <DescriptionIcon sx={{ color: theme.palette.text.secondary, mr: 1 }} />
                    <Typography 
                      variant="body1"
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: '14px',
                        fontWeight: 500,
                        flex: 1
                      }}
                    >
                      {getFileNameFromUrl(userData.profile.document_file)}
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleDownload(userData.profile.document_file)}
                    sx={{
                      textTransform: 'none',
                      borderColor: theme.palette.divider,
                      color: theme.palette.text.primary,
                      '&:hover': {
                        borderColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    Visualizar
                  </Button>
                </Box>
              ) : (
                // Upload box when no document exists
                <Box
                  sx={{
                    border: '1px dashed #D1D5DB',
                    borderRadius: '8px',
                    p: 4,
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: theme.palette.background.paper,
                    width: '100%',
                    '&:hover': {
                      borderColor: theme.palette.action.hover,
                      backgroundColor: theme.palette.action.selected,
                    },
                  }}
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                    accept=".pdf,.jpg,.jpeg,.png"
                  />
                  <UploadFileIcon sx={{ fontSize: 40, color: theme.palette.text.secondary, mb: 2 }} />
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 1,
                      color: theme.palette.text.primary,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                  >
                    Arraste e solte ou clique para fazer upload
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '14px'
                    }}
                  >
                    PDF, JPG ou PNG até 10MB
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
