import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, Typography, Button, Paper } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const PaymentSuccess = () => {
  const location = useLocation();
  const { transactionId, paymentId } = location.state || {};

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: '#0a0f0d',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          textAlign: 'center',
          bgcolor: '#1a1f1d',
          color: '#fff',
        }}
      >
        <CheckCircleIcon
          sx={{ fontSize: 64, color: '#00ff00', mb: 2 }}
        />
        <Typography variant="h4" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Thank you for your purchase. Your payment has been processed successfully.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Transaction ID: {transactionId}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Payment ID: {paymentId}
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          sx={{
            bgcolor: '#00ff00',
            color: '#000',
            '&:hover': {
              bgcolor: '#00cc00',
            },
          }}
        >
          Return to Dashboard
        </Button>
      </Paper>
    </Box>
  );
};

export default PaymentSuccess;
