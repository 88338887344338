import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : (process.env.REACT_APP_API_URL || 'http://localhost:8000') + '/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Function to get CSRF token from cookies
function getCsrfToken() {
  const name = 'csrftoken';
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Function to get auth token from localStorage
const getAuthToken = () => {
  return localStorage.getItem('token');
};

// Add request interceptor to include CSRF token and Auth token
api.interceptors.request.use(
  config => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
    
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        // Clear token and redirect to login
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      return Promise.reject(error);
    } else if (error.code === 'ERR_CONNECTION_REFUSED') {
      console.error('Connection to server failed. Please ensure the backend service is running.');
      // You can dispatch an action here to show a user-friendly error message
    }
    return Promise.reject(error);
  }
);

// Gateway API endpoints
export const getGatewayApiKeys = () => {
  return api.get('/gateway/api-keys/');
};

export const updateGatewayApiKey = (gateway, apiKey) => {
  return api.post('/gateway/api-keys/', {
    gateway,
    api_key: apiKey
  });
};

export default api;
